import React from "react"

import Layout from "@src/components/Layout"
import Loader from "@src/components/Loader"
import Text from "@src/components/Text"
import useDemoForm from "@src/hooks/useDemoForm"
import getAppUrl from "@src/utils/getAppUrl"
import illustration from "@src/icons/get-a-demo-enterprise.svg"

import * as styles from "./GetADemoEnterprise.module.scss"

const GetADemoEnterprise = ({ location }) => {
  const { form, setForm, errors, submit, loading } = useDemoForm()
  const appUrl = getAppUrl.hook(location)
  const successClassName = loading === "SUCCESS" ? styles.active : ""
  const loadingClassName = loading === "LOADING" ? styles.active : ""
  return (
    <Layout
      title="Get a Demo"
      description="Request a demo to understand how canonic can be a best fit for you."
      url="https://canonic.dev/get-a-demo-enterprise"
      location={location}
      switchOnId="get-a-demo-enterprise"
    >
      <div className={styles.getDemoBox}>
        <div className={styles.getDemo}>
          <div className={styles.getDemoColumn}>
            <Text.H2>We are excited to speak with you!</Text.H2>
            <Text.Subtitle>
              We can give you a demo, help you choose the right plan for your
              team, or share best practices for getting the most out of Canonic.
            </Text.Subtitle>
            <Text.Subtitle noMargin>
              <strong>
                Just drop in a few details and we’ll get in touch.
              </strong>
            </Text.Subtitle>
            <figure className={styles.getDemoColumnIllustration}>
              <img alt="get-a-demo-enterprise" src={illustration} />
            </figure>
          </div>
          <div className={styles.getDemoColumn}>
            <div className={styles.getDemoColumnInputs}>
              <input
                type="text"
                name="first-name"
                value={form.firstName}
                onChange={setForm("firstName")}
                placeholder="First Name*"
              />
              <input
                type="text"
                name="last-name"
                value={form.lastName}
                onChange={setForm("lastName")}
                placeholder="Last Name"
              />
            </div>
            <span
              className={`${styles.getDemoError} ${
                errors[0] ? styles.active : ""
              }`}
            >
              {errors[0]}
            </span>
            <input
              type="text"
              name="email"
              value={form.email}
              onChange={setForm("email")}
              placeholder="Email*"
            />
            <span
              className={`${styles.getDemoError} ${
                errors[1] ? styles.active : ""
              }`}
            >
              {errors[1]}
            </span>
            <input
              type="text"
              name="company"
              value={form.company}
              onChange={setForm("company")}
              placeholder="Company"
            />
            <input
              type="text"
              name="phone"
              value={form.phone}
              onChange={setForm("phone")}
              placeholder="Phone Number"
            />
            <button onClick={submit}>
              <Loader className={`${styles.loader} ${loadingClassName}`} />
              Get a Demo
            </button>
            <div className={`${styles.getDemoSuccess} ${successClassName}`}>
              <div className={styles.getDemoSuccessTitle}>
                <div className={styles.getDemoSuccessIcon}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="15"
                    viewBox="0 0 17 15"
                  >
                    <g
                      fill="none"
                      fillRule="evenodd"
                      transform="translate(-0.7 -2)"
                    >
                      <path
                        fill="#000000"
                        d="M7.85249641,9.71389594 L11.6493699,5.76645583 C12.1034454,5.29392678 12.842726,5.29392678 13.3033684,5.773422 C13.7315568,6.2364387 13.7262743,6.9693848 13.2866867,7.42676486 L8.66487451,12.2335442 C8.21079909,12.7060732 7.47151849,12.7060732 7.01602829,12.2320658 L4.71197867,9.81464903 C4.2686357,9.3532886 4.2686357,8.61501939 4.71197867,8.15436853 C5.16605409,7.68183948 5.90533468,7.68183948 6.36005026,8.15503594 L7.85249641,9.71389594 Z"
                      />
                    </g>
                  </svg>
                </div>
                <h3>We have received your request</h3>
              </div>
              <p>Feel free to use the app and get started!</p>
              <div className={styles.getDemoFinalCta}>
                <a rel="noreferrer" target="_blank" href={appUrl} key={appUrl}>
                  <button>Start for free</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default GetADemoEnterprise
